*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	height: 100%;
	overflow-x: hidden;
}

body {
	min-height: 100%;
	overflow-x: hidden;
}

img {
	height: auto;
	// Make images fluid width ("responsive") by default
	max-width: 100%;
	// Remove whitespace below images (caused by line-height)
	vertical-align: middle;
}

a {
	color: currentColor;
}

p {
	margin-top: 0;
}

hr {
	border: 0;
	border-bottom: 1px solid #cccccc;
	margin: 1em 0;
}