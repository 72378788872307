.Form {
	width: 100%;
	margin: 0 auto;
}

.Form--border {

	.Form-input,
	.Form-selectOptions {
		border: 1px solid $color-gray;
	}
}

.Form-fieldWrapper {
	width: 100%;

	@include media-query($breakpoint-m) {
		display: flex;

		.Form-field:first-child {
			margin-right: 1em;
		}
	}
}

.Form-field {
	margin-bottom: 1em;
	position: relative;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
    
    .Form-select {
		// width: 100%;
	}
	.Form-input {
		width: 100%;
	}
}

.Form-label {
	// width: 100%;
	text-align: left;
	font-size: 0.85rem;
	opacity: 0.5;
}

.Form-input {
	// width: 100%;
	padding: 0.3em 0.75em;
	border: 0;
	border-radius: $border-radius-round;
	font-size: 0.95em;
	// background-color: $color-white-off;
    color: $color-gray-dark;
    
    &:focus {
        outline: none;
    }
}

.Form-inputIcon {
	position: absolute;
	right: 0.5em;
	top: 25%;
	fill: $color-black !important;
}

.Form-select {
	position: relative;

	.Form-selectIcon {
		position: absolute;
		right: 0.5em;
		top: 0.6em;
		color: $color-black;
		z-index: 1;
		pointer-events: none;
	}
}

.Form-selectOptions {
	appearance: none;
	border: 0;
	padding: 0.4em 2em 0.4em 0.75em;
	font-size: 0.9em;
	border-radius: $border-radius-round;

	&:focus {
        outline: none;
    }
}

.Form-textarea {
	padding: 0.3em 0.75em;
	border: 0;
	border-radius: $border-radius;
	font-size: 0.95em;
    color: $color-gray-dark;
    
    &:focus {
        outline: none;
    }
}

.Form--search {
    display: flex;
    justify-content: space-between;
	// flex-wrap: wrap;
    // justify-content: flex-end;
    width: auto;
    
    .Form-field {
        // margin-bottom: 0.5em;
        width: auto;
    }
}