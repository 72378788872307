.Article {

}

.Article-title {
    font-size: 2.5em;
    margin-top: 0;
}

.Article-meta {
    margin-top: -2em;
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: bold;
    display: flex;
    align-items: center;
}


.wp-block-gallery {
    margin-top: 2em;
}

.wp-block-quote {
    font-size: 2em;
    line-height: 1.3;
    position: relative;
    margin-top: 2em;
    margin-bottom: 2em;

    &:after {
        content: '';
        background: url('../robertnieuwenhuis/assets/images/quote.png');
        background-size: cover;
        opacity: 0.7;
        width: 8em;
        height: 8em;
        position: absolute;
        left: -4em;
        top: -3em;
        z-index: 0;
    }

    p {
        position: relative;
        z-index: 1;
    }
}

.wp-block-file__button {
    text-decoration: none;
	background: $color-gray-darker !important;
	color: $color-white;
	padding: 0.75em 1.5em !important;;
	border-radius: $border-radius-round;
	display: inline-block;
	border: 0;
	cursor: pointer;
	transition: all .2s ease-in-out;
    border-bottom: 1px solid rgba($color-white, 0.25);
    font-size: 1em !important;

	&:hover,
	&:active,
	&:focus {
		background: darken($color-gray-darker, 2%) !important;
        color: $color-white !important;
        opacity: 1 !important;
	}
}