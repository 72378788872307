.Breadcrumbs {
    color: $color-gray;
    text-transform: uppercase;
    font-size: 0.75em;
    margin-left: -1em;

    a,
    .breadcrumb_last {
        margin-right: 1em;
        margin-left: 1em;
    }

    a {
        text-decoration: none;

        &:hover {
            color: $color-primary;
        }

    }
}
