/**
 * Lays out cells in a row, with automatically row wrapping.
 *
 * See: https://github.com/suitcss/components-grid/blob/master/lib/grid.css
 * See: http://philipwalton.github.io/solved-by-flexbox/demos/grids/
 */

// Grid container, should only have Grid-cells as children
.Grid {
	display: flex;
	flex-wrap: wrap;
	// Compensate for the gutter
	margin-left: -1em;
	
}

.Grid-cell {
	// Cells are equal height by default
	display: flex;
	// Prepare cell content to take up all available space within a cell
	flex-direction: column;
	// This creates a gutter between cells
	padding-left: 1em;
	// By default, move cells below each other. Using utility classes it is
	// possible to set the cell sizes (per breakpoint), in order to display them
	// next to each other.
	width: 100%;

}

// Modifier: horizontally center a grid cell
.Grid-cell--center {
	margin: 0 auto;
}

// Modifier: vertically align a grid cell to the bottom
.Grid-cell--bottom {
	align-self: flex-end;
}

.Grid--bigGap {
	margin-left: -2em;

	> .Grid-cell {
		padding-left: 2em;
	}
}

.Grid--noGap {
	margin-left: -0em;

	> .Grid-cell {
		padding-left: 0em;
	}
}

// Pinterest using flexbox
.Grid2 {
	padding: 1em;
	max-width: 70rem;
	margin: 0 auto;
	height: 700px;
	display: flex;
	flex-flow: column wrap; /* Shorthand – you could use ‘flex-direction: column’ and ‘flex-wrap: wrap’ instead */
	justify-content: flex-start;
	align-items: flex-start;
}

.Grid-cell2 {
	// background-color: orange;
	min-height: 150px;
	width: calc(33.3333% - 1em);
	margin: 0.5em;
	// padding: 10px;
}

// Pinterest using inline
// https://codepen.io/dudleystorey/pen/yqrhw 

.Grid3 {
	column-width: 320px;
	column-gap: 15px;
  	width: 100%;
	max-width: 70rem;
	margin: 0 auto;
	padding: 0 1em;

	@include media-query($breakpoint-m) {
		padding: 0 2em;
	}
	@include media-query($breakpoint-xl) {
		padding: 0 3em;
		// column-width: 300px;
	}
}

.Grid-cell3 {
	background: #fefefe;
	//box-shadow: 0 1px 2px rgba(34, 25, 25, 0.4);
	margin: 0 2px 15px;
	transition: opacity .4s ease-in-out;
  	display: inline-block;
  	column-break-inside: avoid;
}

.Grid4 {
	display: flex;
	flex-flow: column wrap;
	align-content: space-between;
	/* Your container needs a fixed height, and it
     * needs to be taller than your tallest column. */
	height: 1700px;

	/* Force new columns */
	&:before,
	&:after {
		content: "";
		flex-basis: 100%;
		width: 0;
		order: 2;
	}
}

.Grid-cell4 {
	width: 32%;
	margin-bottom: 2%; /* Optional */
}

/* Re-order items into rows */
.Grid-cell4:nth-child(3n+1) { order: 1; }
.Grid-cell4:nth-child(3n+2) { order: 2; }
.Grid-cell4:nth-child(3n)   { order: 3; }

// ff nightly or chrome with flags enabled
// https://www.youtube.com/watch?v=Dz9BzY21Zks&list=PLQkVA6z3dFvbnBJetfYDAF3-cG_ubgdZR
// http://gridbyexample.com/
// Rachel Andrew F.T.W
.Grid5 {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 10px;
	grid-auto-rows: minmax(347px, auto);
	grid-auto-flow: dense;
	padding: 10px;
}

.Grid-cell5 {
}

//.span-2 {
//	grid-column-end: span 2;
//	grid-row-end: span 2;
//}
//
//.span-3 {
//	grid-column-end: span 3;
//	grid-row-end: span 4;
//}




/* ---- grid ---- */

.Grid6 {
	background: #EEE;
	max-width: 70rem;
	counter-reset: grid-item;

	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

/* ---- grid-item ---- */

.Grid-cell6 {
	width: 350px;
	height: 120px;
	float: left;
	background: #D26;
	border: 2px solid #333;
	border-color: hsla(0, 0%, 0%, 0.5);
	border-radius: 5px;
	margin-bottom: 2em;
}

.Grid-cell6--width2 { width: 320px; }
.Grid-cell6--width3 { width: 480px; }
.Grid-cell6--width4 { width: 720px; }

.Grid-cell--height2 { height: 200px; }
.Grid-cell--height3 { height: 260px; }
.Grid-cell--height4 { height: 360px; }

.Grid-cell6:before {
	counter-increment: Grid-cell;
	content: counter(Grids-cell);
	display: block;
	color: white;
	padding-top: 0.2em;
	text-align: center;
	font-size: 1.4rem;
}

// https://w3bits.com/css-grid-masonry/

.masonry-wrapper {
	max-width: 70rem;
	margin-right: auto;
	margin-left: auto;

	padding: 0 1em;

	@include media-query($breakpoint-m) {
		padding: 0 2em;
	}
	@include media-query($breakpoint-xl) {
		padding: 0 3em;
	}
}
.masonry {
	display: grid;
	grid-template-columns: repeat(1, minmax(100px,1fr));
	grid-gap: 15px;
	grid-auto-rows: 0;
}
@media only screen and (max-width: 1023px) and (min-width: 768px) {
	.masonry {
		grid-template-columns: repeat(2, minmax(100px,1fr));
	}
}
@media only screen and (min-width: 1024px) {
	.masonry {
		grid-template-columns: repeat(3, minmax(100px,1fr));
	}
}
.masonry-item {
	overflow: hidden;
}
.masonry-content {
	overflow: hidden;
}
//.masonry-item {
//	filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, .3));
//	transition: filter .25s ease-in-out;
//}
//.masonry-item:hover {
//	filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, .3));
//}
//.masonry-content {
//	overflow: hidden;
//}
//.masonry-item {
//	color: #111111;
//	background-color: #f9f9f9;
//}
//.masonry-title, .masonry-description {
//	margin: 0;
//}
//.masonry-title {
//	font-weight: 700;
//	font-size: 1.1rem;
//	padding: 1rem 1.5rem;
//}
//.masonry-description {
//	padding: 1.5rem;
//	font-size: .75rem;
//	border-top: 1px solid rgba(0, 0, 0, .05);
//}
//.masonry-footer {
//	font-size: .75em;
//	opacity: .25;
//	text-align: center;
//	padding-top: 3em;
//	padding-bottom: 3em;
//	margin-bottom: -1.5em;
//	transition: opacity 1s ease-in-out;
//}
//.masonry-footer a {
//	color: currentColor;
//}
//.masonry-footer:hover, .masonry-footer:active, .masonry-footer:focus {
//	opacity: .75;
//}