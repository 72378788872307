
.Card {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    box-shadow: 3px 5px 5px -2px rgba(34,25,25,.4);

    flex-grow: 1;
    height: 100%;
}

.Card--sticky {
    box-shadow: none;
}

.Card--hover {
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {

        .Card-content {
            background-image: linear-gradient(to right, rgba($color-gray-dark, 0.9) 0%, rgba($color-black, 0.9) 100%);
        }
        .Card-hover {
            max-height: 20em;
            opacity: 1;
        }
    }
}

.Card-figure {
    position: relative;
    width: 100%;
    min-width: 347px;
    display: flex;
    align-items: center;
    background-color: $color-gray-light;

    flex-grow: 1;
    height: 100%;
}

.Card-figureImage {
    width: 100%;
}

.Card-content {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    // background-color: rgba($color: #000000, $alpha: 0.5);
    background-image: linear-gradient(to right, rgba($color-gray-dark, 0.55) 0%, rgba($color-black, 0.55) 100%);
    color: $color-white;
    padding: 1em;
    transition: all 0.3s ease-in-out;
    color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

.Card-hover {
    max-height: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
}

.Card-title {
    // text-transform: uppercase;
    text-align: center;
    font-size: 1.3em;
    color: $color-white;
    font-weight: bold;
}

.Card-meta {
    font-weight: bold;
    margin-bottom: 0.5em;
    .Icon {
        margin-top: -0.15em;
    }
}

.Card-intro {
    margin-bottom: 0.5em;
    font-size: 0.9em;
}

// .Card-button {
//     margin-top: 1em;
// }


.Card-label {
    position: absolute;
    left: -1px;
    top: -6px;
    background: black;
    transform: rotate(1deg);
    width: 90px;
    height: 90px;
    transition: all .3s ease-in-out;
    z-index: 3;

    .Card-labelText {
        z-index: 2;
        color: #333;
        position: absolute;
        top: 3.5em;
        left: -0.2em;
        font-size: .8em;
        line-height: 1.4;
        transform: rotate(-45deg);
        width: 9em;
        transition: all .3s ease-in-out;
    }

    &:before {
        content: "";
        position: absolute;
        background-image: linear-gradient(135deg, rgba($color-gray, 1) 0%, rgba($color-gray, 1) 50%, rgba($color-white, 1) 53%, rgba($color-gray-light, 1) 100%);
        background-size: cover;
        top: 0%;
        left: 0%;
        box-shadow: 2px 2px 7px rgba(0,0,0,0.2);
        width: 91px;
        height: 91px;
        transition: all .3s ease-in-out;
        z-index: -1;
    }

    &:after {
        z-index: -2;
        position: absolute;
        content: "";
        bottom: 8px;
        right: 4px;
        left: auto;
        width: 80%;
        top: 80%;
        background: transparent;
        box-shadow: 0 15px 10px rgba($color-black, 0.8);
        transform: rotate(11deg);
        transition: all .3s ease-in-out;
    }
}
