.Paging,
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}

.Paging-item,
.page-numbers {
    color: $color-white;
    border-bottom: 1px solid rgba($color-white, 0.35);
    padding: 0.9em 1.25em;
    min-width: 2.5em;
    cursor: pointer;
    border-radius: $border-radius-round;
    background-color: $color-gray-darker;
    text-align: center;
    text-decoration: none;
    margin-right: 0.5em;

    &:active,
    &:hover,
    &:focus {
        background-color: darken($color-gray-darker, 5%);
        color: $color-white;
    }
}

.Paging-current,
.page-numbers.current {
    background-color: darken($color-gray-darker, 5%);
    color: $color-white;
}

.prev,
.next {
    max-width: 2em;
    padding: .9em 1em;
}
.screen-reader-text {
    display: none;
}