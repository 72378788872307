
// On mobile there is a pure CSS dropdown menu when you click on the label/hamburger

.Nav {
    position: relative;
    display: flex;
    align-items: center;
}

.Nav-list {
    position: relative;
    z-index: 1;

	// Remove bullets
	list-style: none;
    margin-bottom: 0;
    
	// Remove default space allocated for bullets
	margin-top: 0;
    padding-left: 0.5em;
    text-align: center;

    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.menu-item,
.Nav-list-item {
    margin-right: 0.75em;

    &:last-child {
        margin-right: 0;
    }
}

.current-menu-item .Nav-link {
    text-decoration: none;
    border-bottom: 1px solid currentColor;
}

.Nav-link {
    text-decoration: none;
    padding-bottom: 0.25em;
    font-size: 1.1em;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        border-bottom: 1px solid currentColor;
    }
}

.Nav--languages {
    font-size: 0.8em;
    text-align: right;
    justify-content: flex-end;
    margin-left: 2em;

    .Nav-list {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        background-color: $color-gray-dark;
        border-radius: $border-radius-round;
        color: $color-white;
        padding: 0.15em;
    }

    .Lang-link {
        border-radius: $border-radius-round;
        padding: 0.3em 0.75em;
        text-decoration: none;
        text-transform: lowercase;

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }
    }
    
    .current-lang .Lang-link {
        background-color: $color-gray-light;
        color: $color-gray-dark;
    }

    .lang-item {
        margin-right: 0;
    }
}
