// /**
//  * Horizontally centered content area.
//  */

.Container {
	margin: 0 auto;
	max-width: 70rem;
	padding: 1em;
	// min-width: 1200px;
}

// // Modifier: full width container on smaller viewports
.Container--fullWidth {
	padding: 0;
}

@include media-query($breakpoint-m) {

	.Container {
		padding: 0 2em;
	}
}

@include media-query($breakpoint-xl) {

	.Container {
		padding: 0 3em;
	}
}


.Container--small {
	max-width: 55rem;
}