.Background {
    background-color: $color-white;
    padding: 3em 0;
    position: relative;
}

.Background-content {
    width: 100%;
}

.Background--smallPadding {
    padding: 1em 0;
}

.Background--vh {
    min-height: calc(100vh - 82px);
    display: flex;
    align-items: center;
}

.Background--primary {
    background-color: $color-primary;
    color: $color-white;
}

.Background--primaryLight {
    background-color: $color-primary-light;
    color: $color-white;
}

.Background--primaryDark {
    background-color: $color-primary-dark;
    color: $color-white;
}

.Background--grayLight {
    background-color: $color-gray-light;
}

.Background--gray {
    background-color: $color-gray;
}

.Background--border {
    background-color: rgba($color-gray-dark, 0.2);
    border-top: 1px solid rgba($color-gray-light, 0.2);
    border-bottom: 1px solid rgba($color-gray-light, 0.2);
    box-shadow: 0px 0px 0px 1px rgba(0,0,0,.4);
    z-index: 1;
    .Card-label:before {
        background-image: linear-gradient(135deg, rgba(#484848, 1) 0%, rgba(#484848, 1) 50%, rgba($color-white, 1) 53%, rgba($color-gray-light, 1) 100%);
    }
}

.Background--withCards {

    h3 {
        border-bottom: 1px solid rgba($color-gray-light, 0.35);
        padding-bottom: 0.8em;
        margin-bottom: 0.8em;
        margin-top: 0;
    }

    .has-6-columns p {
        font-size: 0.9em;
    }
}
