.Page {
    text-align: center;
    padding-bottom: 2em;
}

.Page-subtitle {
    letter-spacing: 5px;
    text-transform: uppercase;
    font-size: 0.9em;
    opacity: 0.4;

    p {
        opacity: .4;
    }
}

.Page-title {
    font-size: 2.5em;
    margin-top: 0.25em;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-weight: bold;
    // letter-spacing: 5px;
}

.Page-content {
    padding-top: 4em;
    padding-bottom: 4em;
}