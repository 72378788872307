.Hero {
    min-height: calc(100vh - 134px);
    overflow: hidden;
    position: relative; 

    border-bottom: 1px solid rgba($color-gray-light, 0.2);
    box-shadow: 0px 0px 0px 1px rgba(0,0,0,.4);

    .textwidget {
        margin-top: -2em;
    }
}

.Hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-white;
    position: relative;
    text-align: center;
    width: 100%;
    color: $color-white;
    min-height: calc(100vh - 134px);
    padding-top: 3em;
}

.Hero-container {
    width: 100%;
}

.Hero-figure {
    position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
    bottom: 0;
    overflow: hidden;
    background: $color-black;

	&:after {
		content: '';
		background-image: linear-gradient(to right, rgba($color-gray-dark, 0.5) 0%, rgba($color-black, 0.5) 100%);
        position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		bottom: 0;
	}
}

.Hero-figure img,
.Hero-figureImage {
	min-height: 100%;
	min-width: 1024px;
	width: 100%;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.Hero-title {
    font-size: 2.2em;

    @include media-query($breakpoint-xl) {
        font-size: 3.5em;
        // max-width: 180px;
    }
}

.Hero-more {
    position: absolute;
    margin: auto;
    bottom: 2em;
    left: calc(50% - 0.5em);
    font-size: 1.5em;
    transition: .3s ease-in-out all;

    &:hover,
    &:active,
    &:focus {
        bottom: 1.5em;
    }
}

.Hero--small {
    min-height: 60vh;

    .Hero-content {
        min-height: 60vh;
    }

    .Hero-title {
        font-size: 2.2em;
    }
}
