.Footer {
    border-top: 1px solid rgba($color-gray-light, 0.2);
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.4);
    background: $color-gray;
    z-index: 1;
	color: rgba($color-white, 0.9);
}

.Footer-pre {
    background: rgba($color-gray-dark, 0.2);
    padding-top: 4.5em;
    padding-bottom: 4.5em;
    text-align: center;
}

.Footer-title {
    margin-bottom: 1em;
    font-size: 2.5em;
    margin-top: 0;
    margin-bottom: 0.5em;
}

.Footer-copy {
    background: rgba($color-gray-dark, 0.2);
    font-size: 0.7em;
    padding: 0.75em 0 2em;
    text-align: center;
}