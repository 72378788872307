/** --------------------------------------------------------------
 * Wordpress Gallery
 *
 * 
 */

 .gallery-caption {
    font-size: 0.9em;
}

.gallery {
    margin: 0 auto 18px
}

.gallery .gallery-item {
	display: inline-block;
	margin: 0 !important;
	text-align: center;
	width: 33%;
}

.gallery-columns-2 .gallery-item {
    width: 50%
}

.gallery-columns-4 .gallery-item {
    width: 25%
}

.gallery-columns-5 .gallery-item {
    width: 20%
}

.gallery img {
    border: 0 !important;
	padding: 15px;
    transform: scale(1);
    opacity: 1;
    transition: .3s ease-in-out all;
}

// .gallery img:hover {
//     opacity: 1;
//     transform: scale(1.05);
// }

.gallery-columns-2 .attachment-medium {
	max-width: 92%;
	height: auto;
}

.gallery-columns-4 .attachment-thumbnail {
	max-width: 84%;
	height: auto;
}

.gallery .gallery-caption {
	color: #888;
	font-size: 12px;
	margin: 0 0 12px;
}

.gallery dl, .gallery dt {
    margin: 0
}
.gallery br+br {
    display: none
}
