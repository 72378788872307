.Header {
    width: 100%;
    color: $color-white;
	transition: all .3s ease-in-out;
	background: transparent;
    position: fixed;
    z-index: 2;
    background: rgba($color-white, 0.15);
    background: rgba($color-gray, 1);
    border-bottom: 1px solid rgba($color-gray-light, 0.2);
    box-shadow: 0px 0px 0px 1px rgba(0,0,0,.4);

	&.is-scrolled {
        background: rgba($color-gray, 1);
        top: 0;
        
        .Header-pre {
            max-height: 0;
            padding: 0;
        }
        
		.Header-container {
            padding: 1.5em 1.5em 1.5em 8em;
		}
		
		.Header-title {
			font-size: 1.5em;
        }
        
        .Header-fold {
            width: 116px;
            height: 116px;

            .Header-foldSlogan {
                top: 5.3em;
                left: 2.4em;
                font-size: .63em;
            }

            &:before {
                width: 116px;
                height: 116px;
            }
        }
        
        .Header-languages {
            max-height: 0;
            overflow: hidden;
        }

        @include media-query($breakpoint-m) {
            
            .Header-container {
                padding: 1.5em 2em 1.5em 8em;
            }
        }
        @include media-query($breakpoint-xl) {

            .Header-container {
                padding: 1.5em 3em 1.5em 3em;
            }

            .Header-languages {
                max-height: 2em;
            }
        }

	}
}

.Header-height {
    display: block;
    height: 134px;
}

.Header-pre {
    background: rgba($color-white, 0.15);
    transition: all .3s ease-in-out;
    max-height: 4em;
    padding: 0;
    text-align: left;
    font-size: 0.8em;
    max-height: 0;
    overflow: hidden;

    @include media-query($breakpoint-xl) {
        padding: 0.4em 2em 0.4em 15.5em;
        max-height: 4em;
    }
}

.Header-container {
    z-index: 2;
    position: sticky;
    padding: 1.5em 1.5em 1.5em 8em;
    transition: all .3s ease-in-out;
    
    @include media-query($breakpoint-m) {
        padding: 1.5em 3em 1.5em 8em;
    }
    @include media-query($breakpoint-xl) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2em 3em 2em 12em;
    }
    @include media-query($breakpoint-xxxxl) {
        padding: 2em 3em;
    }
}

.Header-title {
    margin: 0;
	transition: all .3s ease-in-out;
}

.Header-contact {
    font-size: 0.9em;
}

.Header-languages {
    position: absolute;
    right: 1em;
    top: 5.5em;

    @include media-query($breakpoint-xl) {
        position: relative;
        right: inherit;
        top: inherit;
    }
}

.Header-fold {
    position: absolute;
    left: -1px;
    top: -6px;
    background: black;
    transform: rotate(1deg);
    width: 115px;
    height: 115px;
    transition: all .3s ease-in-out;
    z-index: 3;

    .Header-foldSlogan {
        z-index: 2;
        color: #333;
        position: absolute;
        top: 5.3em;
        left: 2.4em;
        font-size: .63em;
        line-height: 1.4;
        transform: rotate(-45deg);
        width: 9em;
        transition: all .3s ease-in-out;
    }

    &:before {
        content: "";
        position: absolute;
        background-image: linear-gradient(135deg, rgba($color-gray, 1) 0%, rgba($color-black, 1) 50%, rgba($color-white, 1) 53%, rgba($color-gray-light, 1) 100%);
        background-size: cover;
        top: 0%;
        left: 0%;
        box-shadow: 2px 2px 7px rgba(0,0,0,0.2);
        width: 116px;
        height: 116px;
        transition: all .3s ease-in-out;
        z-index: -1;
    }

    &:after {
        z-index: -2;
        position: absolute;
        content: "";
        bottom: 8px;
        right: 4px;
        left: auto;
        width: 80%;
        top: 80%;
        background: transparent;
        box-shadow: 0 15px 10px rgba($color-black, 0.8);
        transform: rotate(11deg);
        transition: all .3s ease-in-out;
    }

    @include media-query($breakpoint-xl) {
        width: 176px;
        height: 176px;

        .Header-foldSlogan {
            left: 2.5em;
            top: 5.1em;
            font-size: 0.95em;
        }

        &:before {
            width: 176px;
            height: 176px;
        }
    }
}
